<template>
  <div>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== '#4480C5'"
    :color="SidebarColor"
    mobile-break-point="960"
    width="250px"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    app
    id="main-sidebar"
    class="elevation-4"
  >
  <v-layout wrap py-2>
    <v-flex>
      <v-list dense nav class="mt-6 my-lg-5 my-md-5 my-sm-5 my-16">
        <v-layout align-center justify-end pl-7 class="hidden-sm-and-up">
          <v-flex xs12 sm11 md8 lg6 xl3 class="d-flex align-center">
          <!-- <v-avatar size="50">
            <img src="../../assets/images/user.png" alt="User Image">
          </v-avatar> -->
          <v-avatar size="50" color="white">
            <v-icon large color="primary">mdi-account</v-icon>
          </v-avatar>
          <div class="d-flex flex-column ml-2">
            <!-- <span style="color:black; font-family: intersemibold;">Jacob Chacko</span> -->
            <span v-if="userRole == 'admin'" style="color: white; font-family: intersemibold; font-size:15px;">Admin</span>
            <span v-if="userRole == 'subadmin'" style="color: white; font-family: intersemibold; font-size:15px;">Sub Admin</span>
          </div>
        </v-flex>
        </v-layout>
  
        <v-list class="pt-4 pt-lg-0 pt-md-0 pt-sm-0 " v-if="userRole == 'admin'">
          <v-list-item
            v-for="(item, index) in adminitems"
            :key="item.title"
            :class="{'active-item': activeItem === index}"
            @click="handleClick(item.to, index)"
          >
            <v-list-item-icon class="pa-0 pl-8 mr-4">
              <v-img height="24px" width="24px" :src="activeItem === index ? item.iconblue : item.iconwhite"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="{'active-text': activeItem === index}" style="font-family:interregular;font-size:14px;">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list class="pt-4 pt-lg-0 pt-md-0 pt-sm-0 " v-if="userRole == 'subadmin'">
          <v-list-item
            v-for="(item, index) in subadminitems"
            :key="item.title"
            :class="{'active-item': activeItem === index}"
            @click="handleClick(item.to, index)"
          >
            <v-list-item-icon class="pa-0 pl-8 mr-4">
              <v-img height="24px" width="24px" :src="activeItem === index ? item.iconblue : item.iconwhite"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="{'active-text': activeItem === index}" style="font-family:interregular;font-size:14px;">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-layout wrap>
          <v-flex class="hidden-sm-and-up" pl-11 pt-2>
            <v-btn outlined @click="appLogout" >
                <v-icon color="white">mdi-logout</v-icon>
                <span style="color:white; margin-left: 8px;font-family: interregular;text-transform:uppercase">Logout</span>
              </v-btn>
            </v-flex>
        </v-layout>
       
      </v-list>
    </v-flex>
  </v-layout>
 

  </v-navigation-drawer>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userRole: localStorage.getItem("role"),
    activeItem: 0,
    adminitems: [
      {
        title: "Dashboard",
        iconblue: require("@/assets/images/dash.png"),
        iconwhite: require("@/assets/images/dash1.png"),
        to: "/dashboard",
      },
      {
        title: "Church List",
        iconblue: require("@/assets/images/church_icon.png"),
        iconwhite: require("@/assets/images/church_icon2.png"),
        to: "/churchList",
      },
      {
        title: "Rites",
        // icon: "mdi-church",
        iconblue: require("@/assets/images/plus.png"),
        iconwhite: require("@/assets/images/plus1.png"),
        to: "/ritesList",
      },
      {
        title: "Live Telecasts",
        // icon: "mdi-church",
        iconblue: require("@/assets/images/live1.png"),
        iconwhite: require("@/assets/images/live2.png"),
         to: "/liveTelecast",
      },
      // {
      //   title: "Prayer Requests",
      //   iconblue: require("@/assets/images/chat.png"),
      //   iconwhite: require("@/assets/images/chat1.png"),
      //   // to: "/",
      // },
      {
        title: "Blesssings",
        iconblue: require("@/assets/images/miracle2.png"),
        iconwhite: require("@/assets/images/miracle1.png"),
         to: "/blessingsList",
      },
      {
        title: "Daily Bible Verse",
        iconblue: require("@/assets/images/bible.png"),
        iconwhite: require("@/assets/images/bible1.png"),
        to: "/bibleQuotes",
      },
      {
        title: "Saint Feast Day",
        iconblue: require("@/assets/images/calendar.png"),
        iconwhite: require("@/assets/images/calendar-white.png"),
        to: "/saintList",
      },
      
      {
        title: "Diocese",
        iconblue: require("@/assets/images/churchb.png"),
        iconwhite: require("@/assets/images/churchw.png"),
       to: "/dioceseList",
      },
      {
        title: "Parish Type",
        iconblue: require("@/assets/images/parishtypeb.png"),
        iconwhite: require("@/assets/images/parishtypew.png"),
       to: "/parishTypeList",
      },
      {
        title: "Languages",
        iconblue: require("@/assets/images/langb.png"),
        iconwhite: require("@/assets/images/langw.png"),
       to: "/languageList",
      },
      {
        title: "Country",
        iconblue: require("@/assets/images/countryb.png"),
        iconwhite: require("@/assets/images/countryw.png"),
       to: "/countryList",
      },
      {
        title: "Parish Managers",
        iconblue: require("@/assets/images/manager1.png"),
        iconwhite: require("@/assets/images/manager2.png"),
         to: "/assignParishManager",
      },
      {
        title: "Special Events",
        iconblue: require("@/assets/images/eventb.png"),
        iconwhite: require("@/assets/images/eventw.png"),
         to: "/eventsList",
      },
      {
        title: "Sub Admin",
        iconblue: require("@/assets/images/subadminbb.png"),
        iconwhite: require("@/assets/images/subadminw.png"),
         to: "/subAdminList",
      },

      {
        title: "About Us",
        iconblue: require("@/assets/images/about1.png"),
        iconwhite: require("@/assets/images/about2.png"),
         to: "/about",
      },
      {
        title: "Change password",
        iconblue: require("@/assets/images/passwordb.png"),
        iconwhite: require("@/assets/images/passwordw.png"),
        to: "/changePasswordAdmin",
      },
      {
        title: "Users List",
        iconblue: require("@/assets/images/userb.png"),
        iconwhite: require("@/assets/images/userw.png"),
        to: "/userList",
      },
      {
        title: "Privacy Policy",
        iconblue: require("@/assets/images/policyb.png"),
        iconwhite: require("@/assets/images/policyw.png"),
        to: "/privacyPolicy",
      },
      
      
   
    ],

    subadminitems: [
      {
        title: "Dashboard",
        iconblue: require("@/assets/images/dash.png"),
        iconwhite: require("@/assets/images/dash1.png"),
        to: "/dashboard",
      },
      {
        title: "Church List",
        iconblue: require("@/assets/images/passwordb.png"),
        iconwhite: require("@/assets/images/passwordw.png"),
        to: "/churchList",
      },
     
      // {
      //   title: "Rites",
      //   // icon: "mdi-church",
      //   iconblue: require("@/assets/images/plus.png"),
      //   iconwhite: require("@/assets/images/plus1.png"),
      //   to: "/ritesList",
      // },
      // {
      //   title: "Live Telecasts",
      //   // icon: "mdi-church",
      //   iconblue: require("@/assets/images/live1.png"),
      //   iconwhite: require("@/assets/images/live2.png"),
      //    to: "/liveTelecast",
      // },
      // {
      //   title: "Prayer Requests",
      //   iconblue: require("@/assets/images/chat.png"),
      //   iconwhite: require("@/assets/images/chat1.png"),
      //   // to: "/",
      // },
      // {
      //   title: "Blesssings",
      //   iconblue: require("@/assets/images/miracle2.png"),
      //   iconwhite: require("@/assets/images/miracle1.png"),
      //    to: "/blessingsList",
      // },
      // {
      //   title: "Daily Bible Verse",
      //   iconblue: require("@/assets/images/bible.png"),
      //   iconwhite: require("@/assets/images/bible1.png"),
      //   to: "/bibleQuotes",
      // },
      // {
      //   title: "Saint Feast Day",
      //   iconblue: require("@/assets/images/calendar.png"),
      //   iconwhite: require("@/assets/images/calendar-white.png"),
      //   to: "/saintList",
      // },
      
      // {
      //   title: "Diocese",
      //   iconblue: require("@/assets/images/churchb.png"),
      //   iconwhite: require("@/assets/images/churchw.png"),
      //  to: "/dioceseList",
      // },
      // {
      //   title: "Parish Type",
      //   iconblue: require("@/assets/images/parishtypeb.png"),
      //   iconwhite: require("@/assets/images/parishtypew.png"),
      //  to: "/parishTypeList",
      // },
      // {
      //   title: "Languages",
      //   iconblue: require("@/assets/images/langb.png"),
      //   iconwhite: require("@/assets/images/langw.png"),
      //  to: "/languageList",
      // },
      // {
      //   title: "Country",
      //   iconblue: require("@/assets/images/countryb.png"),
      //   iconwhite: require("@/assets/images/countryw.png"),
      //  to: "/countryList",
      // },
      // {
      //   title: "Parish Managers",
      //   iconblue: require("@/assets/images/manager1.png"),
      //   iconwhite: require("@/assets/images/manager2.png"),
      //    to: "/assignParishManager",
      // },

      // {
      //   title: "Sub Admin",
      //   iconblue: require("@/assets/images/subadminbb.png"),
      //   iconwhite: require("@/assets/images/subadminw.png"),
      //    to: "/subAdminList",
      // },

      {
        title: "About Us",
        iconblue: require("@/assets/images/about1.png"),
        iconwhite: require("@/assets/images/about2.png"),
         to: "/about",
      },
      {
        title: "Change password",
        iconblue: require("@/assets/images/passwordb.png"),
        iconwhite: require("@/assets/images/passwordw.png"),
        to: "/changePasswordSubAdmin",
      },
   
    ],







  
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
 


  methods: {
    handleClick(route, index) {
      this.activeItem = index;
      this.$router.push(route); // Navigate to the route
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
  
};
</script>
<style scoped>
.active-item {
  background-color: white; /* Change background color when active */
}

.active-text {
  color: #37A0F4; /* Change text color when active */
}

.v-list-item-icon img {
  transition: all 0.3s ease;
}
</style>
