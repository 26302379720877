<template>
  <div>
    <v-app-bar app clipped-left clipped-right color="white" dark>
      <v-toolbar-title class="align-center d-flex">
        
          <v-layout wrap align-center justify-start>
            <v-flex xs2 sm2 md2 lg2>
              <v-btn class=" mt-1 ml-5" :style="circleButtonStyle" @click="toggleSidebar" icon>
                <v-icon style="color:white">mdi-menu</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs10 sm10>
              <router-link to="/">
                <v-img src="../../assets/jesus_logo2.png" width="180px" contain height="50px" class="ml-7" />
              </router-link>
            </v-flex>
            <v-flex xs10>
            </v-flex>
          </v-layout>
     
      </v-toolbar-title>
      <v-spacer />
      <v-layout wrap justify-end  class="hidden-xs-only">
        <v-flex  class="d-flex align-center justify-end">
          <!-- <v-avatar size="50">
            <img src="../../assets/images/user.png" alt="User Image">
          </v-avatar> -->
          <v-avatar size="40" color="#4480C5">
            <v-icon size="30px"  color="white">mdi-account</v-icon>
          </v-avatar>
          <div class="d-flex flex-column ml-2">
            <span style="color:black; font-family: intersemibold;">{{ formattedUserRole }}</span>

           
          </div>
          <v-divider vertical class="my-divider ml-6 mt-1"></v-divider>
          <v-btn @click="appLogout" text>
            <v-icon color="black">mdi-logout</v-icon>
            <span style="color:black; margin-left: 8px;font-family: intersemibold;font-size:15px">Logout</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userRole: localStorage.getItem("role"),
    circleButtonStyle: {
      backgroundColor: '#62B5F8',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }

  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    formattedUserRole() {
      if (this.userRole === "admin") {
        return "Admin";
      } else if (this.userRole === "subadmin") {
        return "Sub Admin";
      } else {
        return ""; // Default case if userRole doesn't match any known role
      }
    },
  },
  beforeMount() {
    // this.getProfile();
  },
  methods: {
    toggleSidebar() {
      this.setSidebarDrawer(!this.Sidebar_drawer);
    },
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    // getProfile() {
    //   // this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/user/me",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       //this.appLoading = false;
    //       if (response.data.status) {
    //         this.userData = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>

<style>
.logo-icon {
  display: flex;
  align-items: center;
}

.my-divider {
  height: 35px;
  /* Adjust the height as needed */
  width: 1px;
  margin-top: 1px;
  background-color: #C3C3C3;
  /* Adjust the color as needed */
}

.router-link {
  text-decoration: none;
}

.text-color {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}

.size {
  z-index: 1;
  position: absolute;
  border: 2px solid black;
  left: 0;
  top: 0;
}
</style>
